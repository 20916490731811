//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
  >
    <path fill="#C4F0FF" d="M36 0a36 36 0 1 1 0 72 36 36 0 0 1 0-72z" />
    <path
      transform="translate(20 20)"
      fill="#6772E5"
      d="M26.67 19.56h-7.11v7.1h-7.12v-7.1h-7.1v-7.12h7.1v-7.1h7.12v7.1h7.1v7.12zM28.44 0H3.56A3.54 3.54 0 0 0 0 3.56v24.88A3.56 3.56 0 0 0 3.56 32h24.88A3.56 3.56 0 0 0 32 28.44V3.56C32 1.58 30.4 0 28.44 0z"
    />
  </svg>
);
