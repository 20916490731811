//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
  >
    <path
      fill="#6772E5"
      id="path0_fill"
      d="M36 0a36 36 0 1 1 0 72 36 36 0 0 1 0-72z"
    />
    <path
      fill="#C4F0FF"
      transform="translate(20 20)"
      id="path1_fill"
      d="M30.6 10.8c1 0 1.8.77 1.8 1.8a1.8 1.8 0 1 1-3.6 0c0-1.03.77-1.8 1.8-1.8zM0 0v16.2c0 5.33 3.94 9.9 9.25 10.64A11.55 11.55 0 0 0 20.7 36a11.7 11.7 0 0 0 11.7-11.7v-6.64A5.41 5.41 0 0 0 36 12.6a5.4 5.4 0 1 0-10.8 0 5.4 5.4 0 0 0 3.6 5.06v6.48c0 4.5-3.6 8.1-8.1 8.1a7.98 7.98 0 0 1-7.7-5.42c5-1.08 8.6-5.58 8.6-10.62V0h-7.2v5.4H18v10.8a7.2 7.2 0 0 1-14.4 0V5.4h3.6V0H0z"
    />
  </svg>
);
