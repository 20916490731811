// @flow
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import ArrowLink from "../components/ArrowLink";
import DontSeeYour from "../components/DontSeeYour";
import ExpandableList from "../components/ExpandableList";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import Page from "../components/Page";
import Section from "../components/Section";
import SurgeryIcon from "../icons/Hospital2";
import StethIcon from "../icons/Stethoscope";
import { MEDIUM_BREAKPOINT, query } from "../style";

import type {
  ImageSharp,
  ListItemType,
  Service,
  Procedure,
  ImageType
} from "../types";

const Child = ({ service }: { service: Service | Procedure }) => {
  return (
    <div style={{ paddingBottom: 20 }}>
      {/* <ConditionHeader style={{ marginTop: 35 }}>
        What is {service.name}?
      </ConditionHeader> */}
      <ConditionBody>{service.description.description}</ConditionBody>
      {/*<ConditionHeader>
        Why choose the Spine Institute of Idaho to help you?
      </ConditionHeader>
      {service.why &&
        service.why.why && <ConditionBody>{service.why.why}</ConditionBody>}
      */}
      {service.link && (
        <ArrowLink target="_blank" to={service.link}>
          Learn more about {service.name}
        </ArrowLink>
      )}
    </div>
  );
};

const UnstyledLink = styled(Link)`
  && {
    box-shadow: none;
    text-decoration: none;
    background: none;
    display: block;
  }
`;

const ConditionHeader = styled.h4`
  && {
  }
`;

const ConditionBody = styled.p`
  && {
  }
`;

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    allContentfulService: { edges: Array<{ node: Service }> },
    allContentfulProcedure: { edges: Array<{ node: Procedure }> }
  }
};

export default class Index extends React.Component<Props> {
  render() {
    const {
      mainImage,
      secondImage,
      thirdImage,
      allContentfulService,
      allContentfulProcedure
    } = this.props.data;

    const serviceList: Array<ListItemType> = allContentfulService.edges.map(
      ({ node }: { node: Service }) => ({
        name: node.name,
        shortDescription: node.shortDescription,
        children: <Child service={node} />
      })
    );

    const procedureList: Array<ListItemType> = allContentfulProcedure.edges.map(
      ({ node }: { node: Procedure }) => ({
        name: node.name,
        shortDescription: node.shortDescription,
        children: <Child service={node} />
      })
    );

    return (
      <Page title="Our services and procedures">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Procedures and services"
          subtitle="Our focus is to provide a proper diagnosis and treatment plan for each individual we see. Restoring your health is at the heart of what we do."
          cta="Make an appointment"
        />
        <StyledSection color="#E9E5FA">
          <Intro>
            <TitleWrap>
              <StethIcon style={{ flex: "0 0 auto" }} />
              <Title>Services</Title>
            </TitleWrap>
            <Body>
              Living with a painful spine condition can severely impact your
              quality of life and be confusing, we understand that. Our team
              empathizes with what you’re going through and can help ease
              concerns. We will work with you to develop a treatment plan that
              best fits your specific needs.
            </Body>
          </Intro>
          <ListSection>
            <ExpandableList list={serviceList} />
          </ListSection>
        </StyledSection>
        <StyledSection color="#6671e6">
          <Intro>
            <TitleWrap>
              <SurgeryIcon style={{ flex: "0 0 auto" }} />
              <Title style={{ color: "white" }}>Surgical Procedures</Title>
            </TitleWrap>
            <Body style={{ color: "white" }}>
              Our surgeons are fellowship trained and use leading-edge
              technology to offer a full spectrum of spine surgery. We
              specialize in everything from minimally invasive procedures to
              complex deformity correction. When you work with our surgeons and
              surgical team, you quickly see that you are being cared for by a
              tremendous group of people dedicated to your health.
            </Body>
          </Intro>
          <ListSection>
            <ExpandableList list={procedureList} />
          </ListSection>
        </StyledSection>
        <DontSeeYour
          header="Don't see a procedure?"
          body="These are only a small sample of the range of procedures and services we offer. If you don’t see what you’re looking for, that doesn’t mean we don’t do it. Contact us and we’ll be happy to chat about it!"
        />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const TitleWrap = styled.div`
  && {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledSection = styled(Section)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
    padding-top: 280px;
  `};
  }
`;

const ListSection = styled.section`
  && {
    margin: 0px -20px;
  }
`;

const Intro = styled.section`
  && {
  }
`;

const Title = styled.h1`
  && {
    text-align: center;
    display: inline-block;
    margin: 0px 20px;
    flex: 0 0 auto;
  }
`;

const Body = styled.p`
  && {
    max-width: 500px;
    margin: auto;
    font-weight: 400;
    font-size: 17px;
  }
`;

export const pageQuery = graphql`
  query pagePhotos {
    mainImage: file(relativePath: { eq: "square/pt-2.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/surgery.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/injections.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    allContentfulService(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          description {
            description
          }
          shortDescription
          name
          link
          why {
            why
          }
        }
      }
    }
    allContentfulProcedure {
      edges {
        node {
          description {
            description
          }
          shortDescription
          name
          link
          # why {
          #   why
          # }
        }
      }
    }
  }
`;
